import React from "react"
import { Row, Col } from "react-grid-system"
import { RiRecordCircleLine } from "react-icons/ri"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Button } from "antd"

export default function OurProducts() {
  return (
    <div className="our-products">
      <div className="container">
        <div className="our-products-inner">
          <h2 className="our-products__title">Our <span>Products</span></h2>
          <div className="our-products__content">
            <Row gutterWidth={45} className="our-products__content__row">
              <Col md={6} className="our-products__content__col">
                <div className="our-products__section">
                  <h3 className="our-products__section__title">
                    <a href="https://cultr.id" target="_blank" rel="noopener noreferrer">
                      <span className="our-products__section__title__icon">
                        <RiRecordCircleLine />
                      </span>
                      <span className="our-products__section__title__text">
                        Mendesak
                      </span>
                    </a>
                  </h3>
                  <p className="our-products__section__content">
                    Platform Revolusioner untuk <span>mengatasi segala desakan hidupmu</span>. 
                  </p>
                  <p className="our-products__section__content">
                    Temukan solusi kebutuhanmu bersama kami sekarang juga!
                  </p>
                  <Button type="primary" size="large" className="our-products__section__visit-btn">
                    <a href="https://mendesak.id" target="_blank" rel="noopener noreferrer">
                      Visit Now
                    </a>
                  </Button>
                </div>
              </Col>
              <Col md={6} className="our-products__content__col">
                <div className="our-products__section">
                  <StaticImage 
                    src="../../images/ss-mendesak-home.png"
                    alt="CULTR on Desktop"
                    placeholder="none"
                    className="our-products__section__image__mobile"
                    quality={100}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}