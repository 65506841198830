import React from "react";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

function OurTeamsLargeVersion() {
  return (
    <div className="our-teams__content__lg">
      <div className="container">
        <div className="our-teams__content__lg__row">
          <div className="our-teams__content__lg__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Bharatayuddha
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_oky.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Oky D. Arianto</p>
                <div className="our-teams__card__position">
                  Back-End Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/okyarian_" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:oky@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/okydwiarianto" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-teams__content__lg__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Kahua
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_rietts.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Rietts A. Ruff</p>
                <div className="our-teams__card__position">
                  Front-End Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/riettsruff" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:rietts@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/riettsruff" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Pandawa
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_miki.png"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Paulus M. R. Gumilang</p>
                <div className="our-teams__card__position">
                  Designer &amp; Security Researcher
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/mikiresa_" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:paulus@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/mikiresa" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-teams__content__lg__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Mikronesia
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_davin.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Davin K. Hiuredhy</p>
                <div className="our-teams__card__position">
                  UI/UX Designer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/davin_kurnia" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:davin@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/dafloe" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Nawa Lakra
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_christian.png"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Christian R. Sopaheluwakan</p>
                <div className="our-teams__card__position">
                  Orchestrator
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/howareyouchristian" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:christian@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/christianronaldo" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Polinesia
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_frans.png"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Fransiscus X. B. Caesar</p>
                <div className="our-teams__card__position">
                  Business Developer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/fraberglaive" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:fransiscus@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/fransbagus" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-teams__content__lg__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Mahabharata
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_gilang.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Gilang W. Asmara</p>
                <div className="our-teams__card__position">
                  Back-End Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/gilangwinduasmara" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:gilang@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/gilang-windu-asmara" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Kurawa
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_delfi.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Rhenaldo D. Nugraha</p>
                <div className="our-teams__card__position">
                  Network &amp; Security Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/myouiswan_" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:rhenaldo@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/nayadelfi" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-teams__content__lg__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Melanesia
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_gelion.jfif"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Euaggelion Lewaherilla</p>
                <div className="our-teams__card__position">
                  Content Creator
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/gkribox__" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:euaggelion@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/gelion-lewaherilla" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OurTeamsMediumVersion() {
  return (
    <div className="our-teams__content__md">
      <div className="container">
        <div className="our-teams__content__md__row">
          <div className="our-teams__content__md__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Kahua
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_rietts.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Rietts A. Ruff</p>
                <div className="our-teams__card__position">
                  Front-End Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/riettsruff" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:rietts@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/riettsruff" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Pandawa
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_miki.png"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Paulus M. R. Gumilang</p>
                <div className="our-teams__card__position">
                  Designer &amp; Security Researcher
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/mikiresa_" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:paulus@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/mikiresa" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Bharatayuddha
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_oky.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Oky D. Arianto</p>
                <div className="our-teams__card__position">
                  Back-End Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/okyarian_" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:oky@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/okydwiarianto" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-teams__content__md__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Mikronesia
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_davin.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Davin K. Hiuredhy</p>
                <div className="our-teams__card__position">
                  UI/UX Designer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/davin_kurnia" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:davin@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/dafloe" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Nawa Lakra
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_christian.png"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Christian R. Sopaheluwakan</p>
                <div className="our-teams__card__position">
                  Orchestrator
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/howareyouchristian" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:christian@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/christianronaldo" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Polinesia
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_frans.png"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Fransiscus X. B. Caesar</p>
                <div className="our-teams__card__position">
                  Business Developer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/fraberglaive" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:fransiscus@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/fransbagus" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-teams__content__md__col">
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Mahabharata
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_gilang.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Gilang W. Asmara</p>
                <div className="our-teams__card__position">
                  Back-End Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/gilangwinduasmara" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:gilang@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/gilang-windu-asmara" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Kurawa
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_delfi.jpg"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Rhenaldo D. Nugraha</p>
                <div className="our-teams__card__position">
                  Network &amp; Security Engineer
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/myouiswan_" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:rhenaldo@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/nayadelfi" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-teams__card">
              <div className="our-teams__card__tribe">
                <span className="our-teams__card__tribe__text">
                  Chief of Melanesia
                </span>
              </div>
              <div className="our-teams__card__image-wrapper">
                <StaticImage
                  src="../../images/freestyle_gelion.jfif"
                  alt="People"
                  placeholder="none"
                  className="our-teams__card__image"
                />
              </div>
              <div className="our-teams__card__content">
                <p className="our-teams__card__name">Euaggelion Lewaherilla</p>
                <div className="our-teams__card__position">
                  Content Creator
                </div>
                <div className="our-teams__card__social-media-wrapper">
                  <div className="our-teams__card__social-media">
                    <a href="https://instagram.com/gkribox__" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="mailto:euaggelion@b3.sbs">
                      <FiMail />
                    </a>
                  </div>
                  <div className="our-teams__card__social-media">
                    <a href="https://linkedin.com/in/gelion-lewaherilla" target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OurTeamsSmallVersion() {
  return (
    <div className="our-teams__content__sm">
      <Swiper
        grabCursor={true}
        speed={100}
        breakpoints={{
          // when window width is >= 240px
          240: {
            slidesPerView: 2,
            slidesOffsetAfter: 180
          },
          // when window width is >= 560px
          560: {
            slidesPerView: 3,
            slidesOffsetAfter: 270
          },

        }}
        className="our-teams__content__sm__swiper"
      >
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Nawa Lakra
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_christian.png"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Christian R. Sopaheluwakan</p>
              <div className="our-teams__card__position">
                Orchestrator
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/howareyouchristian" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:christian@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/christianronaldo" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Mikronesia
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_davin.jpg"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Davin K. Hiuredhy</p>
              <div className="our-teams__card__position">
                UI/UX Designer
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/davin_kurnia" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:davin@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/dafloe" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Kahua
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_rietts.jpg"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Rietts A. Ruff</p>
              <div className="our-teams__card__position">
                Front-End Engineer
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/riettsruff" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:rietts@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/riettsruff" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Mahabharata
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_gilang.jpg"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Gilang W. Asmara</p>
              <div className="our-teams__card__position">
                Back-End Engineer
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/gilangwinduasmara" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:gilang@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/gilang-windu-asmara" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Pandawa
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_miki.png"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Paulus M. R. Gumilang</p>
              <div className="our-teams__card__position">
                Designer &amp; Security Researcher
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/mikiresa_" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:paulus@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/mikiresa" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Kurawa
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_delfi.jpg"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Rhenaldo D. Nugraha</p>
              <div className="our-teams__card__position">
                Network &amp; Security Engineer
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/myouiswan_" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:rhenaldo@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/nayadelfi" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="our-teams__card">
            <div className="our-teams__card__tribe">
              <span className="our-teams__card__tribe__text">
                Chief of Polinesia
              </span>
            </div>
            <div className="our-teams__card__image-wrapper">
              <StaticImage
                src="../../images/freestyle_frans.png"
                alt="People"
                placeholder="none"
                className="our-teams__card__image"
              />
            </div>
            <div className="our-teams__card__content">
              <p className="our-teams__card__name">Fransiscus X. B. Caesar</p>
              <div className="our-teams__card__position">
                Business Developer
              </div>
              <div className="our-teams__card__social-media-wrapper">
                <div className="our-teams__card__social-media">
                  <a href="https://instagram.com/fraberglaive" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="mailto:fransiscus@b3.sbs">
                    <FiMail />
                  </a>
                </div>
                <div className="our-teams__card__social-media">
                  <a href="https://linkedin.com/in/fransbagus" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default function OurTeams() {
  return (
    <div className="our-teams">
      <div className="our-teams-inner">
        <div className="container">
          <h2 className="our-teams__title">
            <span>People</span> who make the Magic Happen
          </h2>
        </div>
        <OurTeamsLargeVersion />
        <OurTeamsMediumVersion />
        <OurTeamsSmallVersion />
      </div>
    </div>
  );
}