import React, { useEffect, useState, useCallback, useRef } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import { Link as LinkRS } from "react-scroll";

export default function Header() {
  const navigationOverlayRef = useRef<any>(null);

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";

  const maxScrollY = 150;
  const [y, setY] = useState(isBrowser ? window.scrollY : 0);
  const [isHeaderScrollActive, setIsHeaderScrollActive] = useState(false);

  const onScroll = useCallback(
    e => {
      setIsHeaderScrollActive(y > maxScrollY);
      if(isBrowser) setY(window.scrollY);
    }, [y]
  );

  const navigationOverlayToggle = () => {
    navigationOverlayRef?.current?.classList?.toggle("active");
  };

  const hideNavigationOverlay = () => {
    navigationOverlayRef?.current?.classList?.remove("active");
  };

  useEffect(() => {
    if(isBrowser) {
      setY(window.scrollY);
      window.addEventListener("scroll", onScroll);
    }
    
    return () => {
      if(isBrowser) {
        window.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll]);

  return (
    <div className={`header${isHeaderScrollActive ? " header--scroll" : ""}`}>
      <div className="container">
        <div className="header-inner">
          <div className="header__logo">
            <Link to="https://b3.sbs">
              <StaticImage 
                src="../../images/B3 Corp.png"
                alt="B3 Corp."
                placeholder="none"
                className="header__logo__image"
              />
            </Link>
          </div>
          <div className="header__nav">
            <div className="header__nav__item">
              <LinkRS to="our-story" spy={true} smooth={true} duration={1500} offset={-50}>
                About
              </LinkRS>
            </div>
            <div className="header__nav__item">
              <a href="https://app.hirewithpiper.com/job/b3-corporation" target="_blank" rel="noopener noreferrer">
                Career
              </a>
            </div>
            <div className="header__nav__item">
              <a href="https://blog.b3.sbs" target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </div>
            <div className="header__nav__item">
              <LinkRS to="faq" spy={true} smooth={true} duration={1500} offset={-50}>
                FAQ
              </LinkRS>
            </div>
            <div className="header__nav__item">
              <LinkRS to="our-contact" spy={true} smooth={true} duration={1500} offset={-50}>
                Contact
              </LinkRS>
            </div>
          </div>
          <div className="header__hamburger" onClick={navigationOverlayToggle}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="navigation-overlay" ref={navigationOverlayRef}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div className="navigation-overlay-close" onClick={navigationOverlayToggle}>&times;</div>
          <ul className="navigation-list">
            <li className="navigation-list-item">
              <LinkRS to="our-story" spy={true} smooth={true} duration={1500} offset={-50} onClick={hideNavigationOverlay}>
                About
              </LinkRS>
            </li>
            <li className="navigation-list-item">
              <a href="https://app.hirewithpiper.com/job/b3-corporation" target="_blank" rel="noopener noreferrer">
                Career
              </a>
            </li>
            <li className="navigation-list-item">
              <a href="https://blog.b3.sbs" target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </li>
            <li className="navigation-list-item">
              <LinkRS to="faq" spy={true} smooth={true} duration={1500} offset={-50} onClick={hideNavigationOverlay}>
                FAQ
              </LinkRS>
            </li>
            <li className="navigation-list-item">
              <LinkRS to="our-contact" spy={true} smooth={true} duration={1500} offset={-50} onClick={hideNavigationOverlay}>
                Contact
              </LinkRS>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}