import React from "react";
import { Row, Col } from "react-grid-system";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Footer() {
  const copyrightDate = new Date().getFullYear() === 2021
    ? "2021"
    : `2021 - ${new Date().getFullYear()}`;

  return (
    <div className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-inner">
            <div className="footer-inner-top">
              <Row gutterWidth={45} className="footer-inner-top__row">
                <Col lg={5} className="footer-inner-top__col">
                  <h4 className="text-wrapper">
                    B3 Corporation
                  </h4>
                  <p className="about">We are Gen-Z from Indonesia, who want mitigating climate change with a cultural approach. We wrap all this with CulTech (Cultural Technology), from Indonesia to Worldwide.</p>
                </Col>
                <Col lg={3} sm={6} className="footer-inner-top__col">
                  <div className="footer-inner__content">
                    <h4 className="footer-inner__content__title">Legal</h4>
                    <ul className="footer-inner__content__list">
                      <li className="footer-inner__content__list__item">
                        <Link to="https://drive.google.com/drive/folders/1DxxZdWWnlXygmUrWSRvS4w_XxKk_ReAA?usp=sharing">
                          Media Press Kit
                        </Link>
                      </li>
                      <li className="footer-inner__content__list__item">
                        <Link to="#">
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="footer-inner__content__list__item">
                        <Link to="#">
                          Terms & Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={4} sm={6} className="footer-inner-top__col">
                  {/* <div className="footer-inner__content">
                    <h4 className="footer-inner__content__title">Wish Nominated By</h4>
                    <a href="https://1000startupdigital.id" target="_blank" rel="noopener noreferrer" className="nominated-wannabe__link">
                      <div className="nominated-wannabe__logo-wrapper">
                        <StaticImage
                          src="../../images/1000-startup-digital.png"
                          alt="Gerakan Nasional 1000 Startup Digital"
                          placeholder="none"
                          className="nominated-wannabe__logo"
                        />
                      </div>
                    </a>
                  </div> */}
                  <div className="footer-inner__content">
                    <h4 className="footer-inner__content__title">Sponsored By</h4>
                    <a href="https://aws.amazon.com/activate" target="_blank" rel="noopener noreferrer" className="sponsor-wannabe__link">
                      <div className="sponsor-wannabe__logo-wrapper">
                        <StaticImage
                          src="../../images/aws-activate.png"
                          alt="AWS Activate"
                          placeholder="none"
                          className="sponsor-wannabe__logo"
                        />
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-inner-bottom">
            <p className="copyright">
              <span className="copyright__section">&copy; {copyrightDate} <span className="agency">PT BANGGA BERBANGSA BERBUDAYA</span>.</span> <span className="copyright__section">ALL RIGHTS RESERVED.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}