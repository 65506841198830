import React from "react";

export default function OurTeamsQuote() {
  return (
    <div className="our-teams-quote">
      <div className="container">
        <div className="our-teams-quote-inner">
          <div className="our-teams-quote__section">
            <h3 className="our-teams-quote__section__content">
              <span className="single-sentence"><span className="emphasize">Pursuing the Vision</span></span> <span className="single-sentence">is Our Priority</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}