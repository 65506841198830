import React from "react";

export default function OurProductsComingSoon() {
  return (
    <div className="our-products-coming-soon">
      <div className="container">
        <div className="our-products-coming-soon-inner">
          <div className="our-products-coming-soon__section">
            <h3 className="our-products-coming-soon__section__content">
              <span className="single-sentence">Other Products?</span><span className="single-sentence"> <span className="emphasize">Coming Soon...</span></span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}