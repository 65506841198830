import React, { useEffect, useState, useCallback } from "react";
import { StaticImage } from "gatsby-plugin-image";
// import { RiRecordCircleFill } from "react-icons/ri";

export default function Jumbotron() {
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";

  const maxScrollY = 50;
  const [y, setY] = useState(isBrowser ? window.scrollY : 0);
  const [isHeaderScrollActive, setIsHeaderScrollActive] = useState(false);

  const onScroll = useCallback(
    e => {
      setIsHeaderScrollActive(y > maxScrollY);
      if(isBrowser) setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    if(isBrowser) {
      setY(window.scrollY);
      window.addEventListener("scroll", onScroll);
    }
    
    return () => {
      if(isBrowser) {
        window.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll]);

  return (
    <div className={`jumbotron${isHeaderScrollActive ? " jumbotron--scroll" : ""}`}>
      <div className="container">
        <div className="jumbotron-inner">
          <div className="jumbotron__hero-text">
            <h1>Proud of <span>Nation</span></h1>
            <h1>and <span>Culture</span></h1>
            <div className="plan">
              {/* <div className="plan__icon">
                <RiRecordCircleFill />
              </div> */}
              <div className="plan__text">
                We build something that would be missed in the future.
              </div>
            </div>
          </div>
          <div className="jumbotron__hero-image">
            <StaticImage 
              src="../../images/ils_20.svg"
              alt="Unity in Diversity"
              placeholder="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
}