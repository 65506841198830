import React, { useState, useEffect } from "react"
import Header from "../layouts/Header"
import MyHelmet from "../components/Helmet"
import Jumbotron from "../layouts/Jumbotron";
import MissionAndVision from "../layouts/MissionAndVision";
import OurStory from "../layouts/OurStory";
import ValuesWithBelieves from "../layouts/ValuesWithBelieves";
import OurProducts from "../layouts/OurProducts";
import OurProductsComingSoon from "../layouts/OurProductsComingSoon";
import OurPartners from "../layouts/OurPartners";
import OurTeams from "../layouts/OurTeams";
import OurTeamsQuote from "../layouts/OurTeamsQuote";
import FAQ from "../layouts/FAQ";
import TargetMarket from "../layouts/TargetMarket";
import TargetMarketQuote from "../layouts/TargetMarketQuote";
import OurContact from "../layouts/OurContact";
import Loader from "../components/Loader";
import Footer from "../layouts/Footer";
import Headline from "../layouts/Headline";

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const loadingTime = 1500;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
   }, loadingTime);
  }, []);

  return (
    <>
      <MyHelmet />
      {isLoading && <Loader />}
      <Headline />
      <Header />
      <Jumbotron />
      <OurStory />
      <MissionAndVision />
      <ValuesWithBelieves />
      <OurProducts />
      <OurProductsComingSoon />
      <OurPartners />
      <OurTeams />
      <OurTeamsQuote />
      <FAQ />
      <TargetMarket />
      <TargetMarketQuote />
      <OurContact />
      <Footer />
    </>
  );
}