import React from "react";
import { Row, Col } from "react-grid-system";
import { FaLightbulb, FaHeart } from "react-icons/fa";
import { HiSpeakerphone } from "react-icons/hi";

export default function ValuesWithBelieves() {
  return (
    <div className="values-with-believes">
      <div className="container">
        <div className="values-with-believes-inner">
          <h2 className="values-with-believes__title">Values with <span>Believes</span></h2>
          <div className="values-with-believes__content">
            <Row gutterWidth={35} className="values-with-believes__content__row">
              <Col lg={4} className="values-with-believes__content__col">
                <div className="values-with-believes__content__box">
                  <div className="values-with-believes__content__icon">
                    <FaLightbulb />
                  </div>
                  <h4 className="values-with-believes__content__title">Live</h4>
                  <p className="values-with-believes__content__body">
                    Become a blessing for others.
                  </p>
                </div>
              </Col>
              <Col lg={4} className="values-with-believes__content__col">
                <div className="values-with-believes__content__box">
                  <div className="values-with-believes__content__icon">
                    <FaHeart />
                  </div>
                  <h4 className="values-with-believes__content__title">Love</h4>
                  <p className="values-with-believes__content__body">
                    Anything you do with compassion.
                  </p>
                </div>
              </Col>
              <Col lg={4} className="values-with-believes__content__col">
                <div className="values-with-believes__content__box">
                  <div className="values-with-believes__content__icon">
                    <HiSpeakerphone />
                  </div>
                  <h4 className="values-with-believes__content__title">Loud</h4>
                  <p className="values-with-believes__content__body">
                    Words. Because everyone has a story to tell.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}