import React, { useState, useRef } from "react";
import { Row, Col } from "react-grid-system";
import { AiFillPhone, AiFillMail } from "react-icons/ai";
import { IoLocationSharp, IoLogoTiktok } from "react-icons/io5";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { Link } from "gatsby";
import { Form, Input, Button, Typography } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { FiSend } from "react-icons/fi";
import { IoHeartOutline } from "react-icons/io5";
import { Element } from "react-scroll";

type LayoutType = Parameters<typeof Form>[0]['layout'];

function ContactInformation() {
  return (
    <Col lg={5} className="our-contact__content__col" data-name="contact-information">
      <div className="our-contact__section">
        <h4 className="our-contact__section__title">Contact Information</h4>
        <div className="our-contact__section__body">
          <div className="our-contact__section__body__info">
            <div className="our-contact__section__body__info__item">
              <a href="https://wa.me/62895395232255?text=Dear+B3lievers%2C" target="_blank" rel="noopener noreferrer">
                <span className="our-contact__section__body__info__item__icon">
                  <AiFillPhone />
                </span>
                <span className="our-contact__section__body__info__item__text">+62 895 3952 32255</span>
              </a>
            </div>
            <div className="our-contact__section__body__info__item">
              <a href="mailto:hi@b3.sbs">
                <span className="our-contact__section__body__info__item__icon">
                  <AiFillMail />
                </span>
                <span className="our-contact__section__body__info__item__text">hi@b3.sbs</span>
              </a>
            </div>
            <div className="our-contact__section__body__info__item">
              <span className="our-contact__section__body__info__item__icon">
                  <IoLocationSharp />
              </span>
              <span className="our-contact__section__body__info__item__text">
                We are still looking for <a href="https://g.page/Virtual Office Indonesia" target="_blank" rel="noopener noreferrer">Virtual Office</a> or <a href="https://g.page/Indonesia Startup Incubator" target="_blank" rel="noopener noreferrer">Incubator</a> and <a href="https://g.page/Venture Capital Startup Indonesia" target="_blank" rel="noopener noreferrer">Investor</a>
              </span>
            </div>
          </div>
          <div className="our-contact__section__body__social-media">
            <a 
              href="https://facebook.com/B3Corpora" className="our-contact__section__body__social-media__link"
              data-name="facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a 
              href="https://twitter.com/B3Corpora" className="our-contact__section__body__social-media__link"
              data-name="twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a 
              href="https://instagram.com/b3corpora" className="our-contact__section__body__social-media__link"
              data-name="instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://linkedin.com/company/b3corpora" className="our-contact__section__body__social-media__link"
              data-name="linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a 
              href="https://youtube.com/channel/UCVQnWiwXB5Oae3_VwmDG4Kg" className="our-contact__section__body__social-media__link"
              data-name="youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </a>
            <a 
              href="https://tiktok.com/@b3corpora" className="our-contact__section__body__social-media__link"
              data-name="tiktok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoTiktok />
            </a>
          </div>
          <div className="our-contact__section__body__quarter-circle"></div>
        </div>
      </div>
    </Col>
  );
}

function ContactForm() {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const [isSendLoading, setIsSendLoading] = useState(false); 

  const recaptchaRef = useRef(null);

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const handleChangeRecatpcha = (value: any) => {
    setIsRecaptchaVerified(value ? true : false);
  };

  const onSend = (values: any) => {
    setIsSendLoading(true);
  };

  return (
    <Col lg={7} className="our-contact__content__col" data-name="contact-form">
      <div className="our-contact__section">
        <h4 className="our-contact__section__title">Contact Form</h4>
        <div className="our-contact__section__body">
          <p className="our-contact__section__body__message">Or leave your message here, we'll get back to you ASAP <span className="our-contact__section__body__message__icon"><IoHeartOutline /></span></p>
          <Form
            layout={formLayout}
            form={form}
            requiredMark
            onFinish={onSend}
          >
            <Row gutterWidth={24} className="our-contact__section__body__row">
              <Col md={6} className="our-contact__section__body__col">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required"
                    }
                  ]}
                >
                  <Input 
                    size="large"
                    placeholder="Name"
                  />
                </Form.Item>
              </Col>
              <Col md={6} className="our-contact__section__body__col">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email is required"
                    },
                    {
                      type: "email",
                      message: "Email format is not valid"
                    }
                  ]}
                >
                  <Input placeholder="Email" size="large" />
                </Form.Item>
              </Col>
              <Col md={12} className="our-contact__section__body__col">
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Subject is required"
                    }
                  ]}
                >
                  <Input placeholder="Subject" size="large" />
                </Form.Item>
              </Col>
              <Col md={12} className="our-contact__section__body__col">
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Message is required"
                    }
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    placeholder="Message"
                    rows={4}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="our-contact__section__body__col">
                <div className="our-contact__section__body__flex">
                  <div className="our-contact__section__body__flex__content">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LdWrb0cAAAAAHM_ajYGrPgx_VOwO3e20Jsp_NTh"
                    onChange={handleChangeRecatpcha}
                  />
                  </div>
                  <div className="our-contact__section__body__flex__content">
                    <Button
                      size="large"
                      icon={<FiSend />}
                      loading={isSendLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      Send Message
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Col>
  );
}

export default function OurContact() {
  return (
    <Element name="our-contact" className="our-contact">
      <div className="container">
        <div className="our-contact-inner">
          <h2 className="our-contact__title">Our <span>Contact</span></h2>
          <div className="our-contact__content">
            <Row nogutter className="our-contact__content__row">
              <ContactInformation />
              <ContactForm />
            </Row>
          </div>
        </div>
      </div>
    </Element>
  )
}