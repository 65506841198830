import React from "react";

export default function TargetMarketQuote() {
  return (
    <div className="target-market-quote">
      <div className="container">
        <div className="target-market-quote-inner">
          <div className="target-market-quote__section">
            <h3 className="target-market-quote__section__content">
              <span className="single-sentence">Not Easy</span> <span className="single-sentence">doesn't mean Impossible</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}