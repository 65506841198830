import React from "react"
import { Row, Col } from "react-grid-system"
import { StaticImage } from "gatsby-plugin-image"

export default function OurPartners() {
  return (
    <div className="our-partners">
      <div className="container">
        <div className="our-partners-inner">
          <h2 className="our-partners__title">Meet our <span>Wonderful Partners</span></h2>
          <div className="our-partners__content">
            <Row gutterWidth={45} className="our-partners__content__row">
              <Col lg={2} sm={3} xs={4} className="our-partners__content__col">
                <a href="https://arisansecurity.id" target="_blank" rel="noopener noreferrer">
                  <StaticImage 
                    src="../../images/asec.png"
                    alt="Arisan Security"
                    placeholder="none"
                  />
                </a>
              </Col>
              <Col lg={2} sm={3} xs={4} className="our-partners__content__col">
                <a href="https://rekomenku.com" target="_blank" rel="noopener noreferrer">
                  <StaticImage 
                    src="../../images/rekomenku.png"
                    alt="Rekomenku"
                    placeholder="none"
                  />
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}