import React from "react";
import { Row, Col } from "react-grid-system";
import { Element } from "react-scroll";

export default function OurStory() {
  return (
    <Element name="our-story" className="our-story">
      <div className="container">
        <div className="our-story-inner">
          <Row className="our-story__row">
            <Col lg={5} md={6} className="our-story__col">
              <div className="our-story__section">
                <h2 className="our-story__section__title">Our <span>Story</span></h2>
                <h3 className="our-story__section__content">
                  <span>Utopian organizations do not just happen; </span>
                  <span>they are purposefully built.</span>
                </h3>
              </div>
            </Col>
            <Col lg={7} md={6} className="our-story__col">
              <div className="our-story__section">
                <p className="our-story__section__content">
                  Starting with small iterations, we think big for the visibility to make the world more colorful through elaboration between culture and technology disruption.
                </p>
                <p className="our-story__section__content">
                  We act like young who’s always Poor, Hungry, and Driven. Carve your journey with us and be the next thoughtful leader in the entire Galaxy.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Element>
  );
}