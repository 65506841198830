import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Element } from "react-scroll";

export default function FAQ() {
  const [faqActive, setFaqActive] = useState<any>(null);

  const faqList = [
    {
      question: "What is B3?",
      answer: "B3 Corporation it's adapted from PT Bangga Berbangsa Berbudaya. In the USA letter format, it would be B3 Corp. We are Indonesian's Information Technology Company."
    },
    {
      question: "Who is B3?",
      answer: "We are Gen-Z from Indonesia, who want mitigating climate change with a cultural approach. We wrap all this with CulTech (Cultural Technology), from Indonesia to Worldwide."
    },
    {
      question: "Where is B3 Operating?",
      answer: "From: Salatiga, Central Java, Indonesia to all over the World. Basically, we trust in remote working culture."
    },
    {
      question: "When is B3 Established?",
      answer: "Officialy on October 11, 2021 at Salatiga. But initially, we start this big idea on July 18, 2021 via Virtual Meetings remotely from various cities in Indonesia."
    },
    {
      question: "Why must use B3 Products?",
      answer: "We trust in Ergonomics concept theory. Easy to Use, Hard to Leave."
    },
    {
      question: "How do B3 make Disruption?",
      answer: "Indonesia is a big nation. We believe that we can become a manufacturer that dominates the world, not just consumers in the eyes of the world. To become an ideal big nation, we must learn. Disruption of technology decides space gap and time. You can become a lifelong learner in any place and any time to make the world more meaningful with #B3SBS."
    }
  ];

  const faqItemOnClick = (target: any) => {
    if(target === faqActive) setFaqActive(null);
    else setFaqActive(target);
  };

  return (
    <Element name="faq" className="faq">
      <div className="container">
        <div className="faq-inner">
          <h2 className="faq__title">Frequently Asked <span>Questions</span></h2>
          <div className="faq__content">
            {faqList.map((item: any, index: any) => (
              <div className={`faq__content__item${faqActive === index ? " faq__content__item--active" : ""}`} onClick={() => faqItemOnClick(index)}>
                <div className="faq__content__item__header">
                  <span className="faq__content__item__header__title">
                    {item.question}
                  </span>
                  <span className="faq__content__item__header__icon">
                    {faqActive === index ? (
                      <AiOutlineMinus />
                    ) : (
                      <AiOutlinePlus />
                    )}
                  </span>
                </div>
                <div className="faq__content__item__body">
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Element>
  );
}