import React from "react";
import { Row, Col } from "react-grid-system";

export default function MissionAndVision() {
  return (
    <div className="mission-and-vision">
      <div className="container">
        <div className="mission-and-vision-inner">
          <Row nogutter className="mission-and-vission__row">
            <Col lg={6} className="mission-and-vision__col">
              <div className="mission mission-and-vision__section">
                <h3 className="mission-and-vision__section__title"><span>Mission</span> with <span>Profession</span></h3>
                <p className="mission-and-vision__section__content">
                  Provide Customer-Centric Service Excellence with diverse unique expertise backgrounds.  
                </p> 
              </div>
            </Col>
            <Col lg={6} className="mission-and-vision__col">
              <div className="vision mission-and-vision__section">
                <h3 className="mission-and-vision__section__title"><span>Vision</span> with <span>Passion</span></h3>
                <p className="mission-and-vision__section__content">
                  Become the greatest asset for Inter-nation.
                </p> 
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}