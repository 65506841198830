import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Loader() {
  return (
    <div className="loader">
      {/* <StaticImage 
        src="../../images/loading.gif"
        alt="Loading..."
        placeholder="none"
      /> */}
      <svg xmlns="http://www.w3.org/2000/svg" style={{margin: "auto", background: "rgb(255, 255, 255)", display: "block", shapeRendering: "auto"}} width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(50 50)">
          <g transform="scale(0.7)">
            <g transform="translate(-50 -50)">
              <g>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.7575757575757576s"></animateTransform>
                <path fill-opacity="0.8" fill="#54afbd" d="M50 50L50 0A50 50 0 0 1 100 50Z"></path>
              </g>
              <g>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102s"></animateTransform>
                <path fill-opacity="0.8" fill="#ff7150" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)"></path>
              </g>
              <g>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.5151515151515151s"></animateTransform>
                <path fill-opacity="0.8" fill="#ffb345" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)"></path>
              </g>
              <g>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="3.0303030303030303s"></animateTransform>
                <path fill-opacity="0.8" fill="#65c55a" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}