import React, { useState } from "react"

export default function Headline() {
  const [isHeadlineActive, setIsHeadlineActive] = useState(true);

  return (
    <div className={`headline${isHeadlineActive ? "" : "--inactive"}`}>
      <div className="container">
        <span className="headline__textWrapper">
          <span className="headline__text">
            B3 Corporation urge peace. Children in Gaza need life-saving
          </span>
          <a 
            href="https://www.unicef.org/emergencies/children-gaza-need-lifesaving-support"
            className="headline__link"
          >
            support
          </a>
          <span className="headline__text">.</span>
        </span>
      </div>
      <span 
        className="headline__closeButton"
        onClick={() => setIsHeadlineActive(false)}
      >
        &times;
      </span>
    </div>
  );
}