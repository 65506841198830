import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function TargetMarket() {
  return (
    <div className="target-market">
      <div className="container">
        <div className="target-market-inner">
          <h2 className="target-market__title">From <span>Indonesia</span> to <span>The World</span></h2>
          <div className="target-market__content">
            <StaticImage 
              src="../../images/indonesia-world-map.jpg"
              alt="Indonesia World Map"
              placeholder="none"
              className="target-market__content__image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}