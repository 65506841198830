import React from "react";
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import "../../styles/main.scss"

export default function MyHelmet() {
  return (
    <Helmet>
      <title>B3 Corporation – #UnityInDiversity</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, shrink-to-fit=no" />
      <meta name="HandheldFriendly" content="true" />
      <meta name="description" content="B3 Corporation or B3 Corpora or B3 Corp is the meridian online Information and Communication Technology Company that's leading the world's cultural ethos with cutting-edge Technology in nowadays era." />
      <meta name="keywords" content="B3, B3 Corp, PT Bangga Berbangsa Berbudaya" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;800&display=swap" rel="stylesheet" />
    </Helmet>
  );
}